const axios = require("axios").default

const defaultHeaders = {
  Authorization: process.env.API_KEY,
  "Content-Type": "application/json",
  Origin: process.env.HOST,
}

const apiUrl = process.env.API_HOST
const apiVersion = process.env.API_VERSION

export class Http {
  post(endpoint, params, callback) {
    axios({
      method: "post",
      url: `${apiUrl}/${apiVersion}/${endpoint}`,
      data: params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }

  get(endpoint, params, callback) {
    axios({
      method: "get",
      url: `${apiUrl}/${apiVersion}/${endpoint}`,
      params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }

  getV2(endpoint, params, callback) {
    axios({
      method: "get",
      url: `${apiUrl}/v2/${endpoint}`,
      params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }

  getFromExternalAPI(endpoint, params, callback) {
    axios({
      method: "get",
      url: endpoint,
      params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }

  put(endpoint, params, callback) {
    axios({
      method: "put",
      url: `${apiUrl}/${apiVersion}/${endpoint}`,
      data: params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }

  delete(endpoint, params, callback) {
    axios({
      method: "delete",
      url: `${apiUrl}/${apiVersion}/${endpoint}`,
      data: params,
      headers: defaultHeaders,
    })
      .then(res => {
        callback(null, res)
      })
      .catch(err => {
        callback(err)
      })
  }
}
